"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var dataTabs = document.querySelectorAll('.tab-content');
var dataLinks = document.querySelectorAll('[data-tab-target]');
var tabMethod = function () {
  if (!dataTabs || dataTabs.length <= 0 || !dataLinks || dataLinks.length <= 0) return;
  dataLinks.forEach(function (item) {
    try {
      item.addEventListener('click', function (e) {
        e.preventDefault();
        _toConsumableArray(dataTabs).map(function (item) {
          return item.classList.remove('active');
        });
        _toConsumableArray(dataLinks).map(function (item) {
          return item.classList.remove('active');
        });
        document.querySelector('#' + item.dataset.tabTarget).classList.add('active');
        item.classList.add('active');
      });
    } catch (error) {
      console.log(error);
    }
  });
}();
document.querySelector('.hamburger-btn').addEventListener('click', function (e) {
  document.querySelector('.fixed-mobile-menu').classList.add('active');
});
document.querySelector('.fixed-mobile-menu button').addEventListener('click', function (e) {
  document.querySelector('.fixed-mobile-menu').classList.remove('active');
});
console.log('rendered');